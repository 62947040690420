<template>
  <main class="h-screen bg-black">
    <div class="w-full h-full flex flex-col items-center justify-center">
      <img src="@/assets/images/logo.png" />
      <h1 class="text-4xl font-bold text-teal-600 mb-6 text-white">
        Loading...
      </h1>
    </div>
  </main>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style></style>
